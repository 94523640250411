<template>
<div class="deliver-material" :style="{'padding-bottom':isEdit? '96px': '16px'}">
  <!-- 上牌信息 -->
  <div class="card">
    <div class="common-title">
      <span>{{ $t('上牌信息') }}</span>
    </div>
    <!-- 省市区选择 -->
    <van-form ref="myForm" input-align="left" error-message-align="left" class="form-info">
      <AddressSystem
        ref="addressSystem"
        :label="$t('上牌城市')"
        :keys="addressKeys" 
        :isShowDistrict="false"
        :inputAlign="'left'"
        :isRequired="true"
        :formData="addressObj"
        :rules="[{required:true}]"
        :isDisabled="!isEdit"
        @change="(val)=>onAddressChange(val)"/>
      <van-field 
        :label="$t('上牌指标')" 
        required
        :rules="[{required:true,message:$t('请选择')}]">
        <template #input>
          <van-radio-group v-model="params.executionJson.targetType"
            direction="horizontal" class="radio-group" :disabled="!isEdit">
            <van-radio :name="1">{{ $t('有指标') }}</van-radio>
            <van-radio :name="2">{{ $t('无指标') }}</van-radio>
          </van-radio-group>
        </template>
      </van-field>
    </van-form>
  </div>
  <!-- 车辆信息 -->
  <div class="card">
    <div class="common-title">
      <span>{{ $t('车辆信息') }}</span>
    </div>
    <van-form ref="myForm2" input-align="left" error-message-align="right" class="form-info">
      <van-field
        :label="$t('车辆类型')"
        :placeholder="$t('请选择')"
        :value="formData.vehicleType | codeFormat"
        :right-icon="isEdit ? 'arrow': ''"
        readonly
        required
        :rules="[{required:true}]"
        :disabled="!isEdit"
        @click="selectField('vehicleType', 'vehicleTypeName', 1005, false)">
      </van-field>
      <template v-if="isShow || isDetailShow">
        <van-field v-model="formData.storageLocation"
            :label="$t('车辆存放地')"
            required
            :placeholder="$t('请输入')"
            maxlength="50"
            :rules="[{required:true}]"
            :disabled="!isEdit"
        />
        <van-field v-model="formData.operationsLegalName"
            :label="$t('运营单位法人代表姓名')"
            required
            :placeholder="$t('请输入')"
            maxlength="50"
            :rules="[{required:true}]"
            :disabled="!isEdit"
        />
        <van-field v-model="formData.operationsLegalPhone"
            :label="$t('运营单位法人代表手机')"
            required
            :placeholder="$t('请输入')"
            maxlength="20"
            :rules="[{required:true}]"
            :disabled="!isEdit"
        />
        <van-field v-model="formData.companyAddress"
            :label="$t('运营单位地址')"
            required
            :placeholder="$t('请输入')"
            maxlength="50"
            :rules="[{required:true}]"
            :disabled="!isEdit"
        />
        <van-field v-model="formData.operationsAddress"
            :label="$t('运营地址')"
            required
            :placeholder="$t('请输入')"
            maxlength="50"
            :rules="[{required:true}]"
            :disabled="!isEdit"
        />
        <van-field v-model="formData.chargingAddress"
            :label="$t('车辆充电桩地址')"
            required
            :placeholder="$t('请输入')"
            maxlength="50"
            :rules="[{required:true}]"
            :disabled="!isEdit"
        />
      </template>
    </van-form>
  </div>

  <div v-if="isEdit" class="common-footer">
    <van-button class="btn back" native-type="button" @click.stop="goBack">{{ $t('返回') }}</van-button>
    <van-button class="btn submit" type="submit" @click="submitAction">{{ $t('提交') }}</van-button>
  </div>
    <!-- 确认弹窗 -->
    <van-dialog v-model="isShowReview" 
      :title="$t('提交确认')" 
      :message="$t('请再次确认以下信息是否已正常填写，此信息提交后将不可更改!')"
      show-cancel-button 
      :cancel-button-text="$t('取消')" 
      :confirm-button-text="$t('确认')"
      confirmButtonColor="#B9921A"
      :close-on-click-overlay="true"
      :before-close="beforeClose">
      <div class="double-check-info">
        <div class="tips">{{ $t('请再次确认以下信息是否已正确填写，此信息提交后将不可更改!') }}</div>
        <div class="line"><span>{{ $t('客户类型')  }}: </span>{{ $route.query.customerType == 1 ? $t('个人') : $t('企业') }}</div>
        <div class="line"><span>{{ $t('上牌城市')  }}: </span>{{ addressObj.provinceName }}{{ addressObj.cityName ? '/' + addressObj.cityName : '' }}</div>
        <div class="line"><span>{{ $t('上牌指标') }}: </span>{{ params.executionJson.targetType === 1 ? $t('有指标') : $t('无指标') }}</div>
        <div class="line"><span>{{ $t('车辆类型') }}: </span>{{ formData.vehicleType | codeFormat }}</div>
      </div>
    </van-dialog>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import deliveryServices from '@/services/deliveryServices.js'
import loading from '@/utils/loading'
import AddressSystem from '@/components/addressSystem.vue'
import commonSelector from '@/components/common-selector'
import baseDataServices from '@/services/baseDataServices'

export default {
  components: { AddressSystem },
  data() {
    return {
      operation: '',
      params: {
        id: '',
        taskStatus: '4004003',
        executionJson: {
          taskCode: 'REGISTRATION_CONFIRM',
          targetType: '',
          provinceCode: '',
          provinceName: '',
          cityCode: '',
          cityName: ''
        },
      },
      addressObj:{},
      addressKeys:{
        cityCode:'cityCode',
        city:'cityName',
        provinceCode:'provinceCode',
        province:'provinceName',
        countyCode:'districtCode',
        county:'districtName',
      },
      formData: {
        vehicleType: '1005009',
        storageLocation: '',
        operationsLegalName: '',
        operationsLegalPhone: '',
        companyAddress: '',
        operationsAddress: '',
        chargingAddress: ''
      },
      deliveryDealerId: '',
      deliveryDealerCode: '', // 交付店所在省份code 310000是上海
      isShowReview: false,
      isShow: false
    }
  },
  computed: {
    ...mapGetters(['dictHash']),
    isEdit() {
      return this.operation === 'edit'
    },
    isDetailShow(){
      return this.addressObj.provinceCode === '310000' && this.$route.query.customerType === 2
    }
  },
  mounted() {
    const { taskId, operation,taskStatus,deliveryDealerId } = this.$route.query
    this.params.id = taskId
    this.operation = operation
    this.deliveryDealerId = deliveryDealerId
    if (deliveryDealerId) {
      this.getDealerInfo()
    }
    if (taskStatus === '4004003') {
      this.getTaskDetail()
    }
  },
  methods: {
    async getDealerInfo(){
      const res = await baseDataServices.getDealerDetail({ id:this.deliveryDealerId })
      this.deliveryDealerCode = res.provinceCode
    },
    async selectField(field, name, dictType, multiple = false) {
      
      const _obj = {
        field,
        dictType,
        multiple,
        reasonList: [],
        // lastSelect: this.followRequest[field]
      }
      commonSelector(_obj).then(res => {
        this.formData[field] = res.dictCode
        this.formData[name] = res.dictName
      })
    },
    onAddressChange(val){
      this.$set(this,'addressObj',val)
      // 当车辆上牌城市为上海 且客户订单类型为 ：企业客户时，页面展示以下字段，必填 customerType, // 购车类型 1 个人 2 企业
      // 当订单类型为私人用车时，无需展示以下信息
      if (val.provinceCode === '310000' && this.$route.query.customerType === 2) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    },
    // 获取任务详情
    async getTaskDetail() {
      const params = {
        taskId: this.params.id,
      }
      const res = await deliveryServices.deliveryTaskDetail(params)
      // 回显数据
      const { targetType,provinceCode,provinceName,cityCode,cityName,vehicleType,storageLocation,operationsLegalName,operationsLegalPhone,companyAddress,operationsAddress,chargingAddress } = res.executionJson
      this.addressObj = { provinceCode,provinceName,cityCode,cityName }
      this.params.executionJson = Object.assign(this.params.executionJson,res.executionJson)
      this.formData = {
        ...this.formData,
        vehicleType,
        storageLocation,operationsLegalName,operationsLegalPhone,companyAddress,operationsAddress,chargingAddress
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    submitAction() {
      const p = this.$refs.myForm.validate()
      const p2 = this.$refs.myForm2.validate()
      const p3 = this.$refs.addressSystem.$refs.form.validate()
      Promise.all([p,p2,p3]).then(async ()=>{
        this.isShowReview = true
      })
    },
    async beforeClose(action, done) {
      if (action === 'confirm') {       
        const { provinceCode,provinceName,cityCode,cityName } = this.addressObj           
        this.params.executionJson = {
          ...this.params.executionJson,
          // targetType: this.targetType,
          provinceCode,
          provinceName,
          cityCode,
          cityName,
          ...this.formData
        } 
        loading.showLoading()
        const res = await deliveryServices.deliveryExecution(this.params)
        loading.hideLoading()
        if (res.code === 0) {
          setTimeout(() => {
            this.$router.go(-1)
          }, 1000)
          done()
        } else {
          done(false)
          this.$toast(res.msg)
        } 
      } else {
        done()
      }    
    }
  }
}
</script>
<style lang="less" scoped>
.deliver-material {
  padding: 16px;
  padding-bottom: 96px;

  .card {
    padding: 0 12px 4px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    margin-bottom: 10px;

    .form-info{
      /deep/ .van-field{
        display: block;
      }
    }
    
  }
}

.radio-group {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.double-check-info{
  padding: 12px;
  font-size: 14px;
  .line{
    line-height: 30px;
    word-wrap: break-word;
    span {
      font-weight: bold;
    }
  }
  .tips{
    // color: red;
    margin-bottom: 12px;
  }
}
</style>